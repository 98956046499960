.card__session__title{
    font-size: 18px;
    color: rgb(46, 46, 46);
    margin-bottom: 4px;
}

.card__session__date{
    font-size: 14px;
    color: rgb(114, 114, 114);
    margin-bottom: 4px;
}

.card__session__status{
    font-size: 14px;
    color: rgb(114, 114, 114);
    margin-bottom: 24px;
}

.list__item h2 {
    font-size: 1.15rem;
    margin: 0.25rem 0;
}
  
.list__item h2.is-fav {
    color: #ff2058;
}
  
.list__item p {
    margin: 0.25rem 0;
}

.button__outline {
    border-radius: 5px;
    margin-right: 8px;
    width: 80px;
    cursor: pointer;
    font: inherit;
    padding: 0.5rem 0.5rem;
  }

.button__outline:focus {
    outline: none;
}

.button__green{
    background: white;
    color: #037f8c;
    border: 1px solid #037f8c;
}

.button__green:active {
    background: #037f8c;
    color: white;
    box-shadow: none;
}

.button__red{
    background: white;
    color: #f24949;
    border: 1px solid #f24949;
}

.button__red:active {
    background: #f24949;
    color: white;
    box-shadow: none;
}

.button__orange{
    background: white;
    color: #f28241;
    border: 1px solid #f28241;
}

.button__orange:active {
    background: #f28241;
    color: white;
    box-shadow: none;
}

.warning__date {
    position:absolute;
    top:10;
    right:0;
    margin-right: 20px;
}

.warning__icon {
    color: #f24949;
    font-size: 32px;
}

.warning__date__text {
    position:absolute;
    top:10;
    right:0;
    margin-top: 30px;
    margin-right: 26px;
    font-size: 12px;
    color: #f24949;
}


@media (hover: hover) {
    .button__green:hover {
        background: #037f8c;
        color: white;
        box-shadow: none;
    }

    .button__red:hover{
        background: #f24949;
        color: white;
        box-shadow: none;
    }

    .button__orange:hover{
        background: #f28241;
        color: white;
        box-shadow: none;
    }
}
  