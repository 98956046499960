.form__container{
    width:100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.form_input_container{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 600px){
    .form_input_container {
      width: 60%;
    }
  }