.item__list__container{
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: 18px;
    margin-left: 0px;
  }

.draggable__icon{
    align-self: center;
    color: #c4c4c4;
    align-content: center;
    justify-content: center;
    width: 20px;
  }
  