.primary__button__container{
    padding: 20px;
}

.primary__button {
    margin-top: 24px;
	box-shadow: 0px 7px 10px -7px #276873;
	background-color:#025959;
	border-radius:8px;
	display:inline-block;
    border: 0px;
	cursor:pointer;
	color:#ffffff;
	font-size:14px;
	font-weight:bold;
	padding:17px 48px;
	text-decoration:none;
    width: 100%;
}
.primary__button:active {
	background-color:#004444;
}

.primary__button__disabled {
    margin-top: 24px;
	box-shadow: 0px 7px 10px -7px #b3b5b6;
	background-color:#cccccc;
	border-radius:8px;
	display:inline-block;
    border: 0px;
	cursor:pointer;
	color:#444444;
	font-size:14px;
	font-weight:bold;
	padding:17px 48px;
	text-decoration:none;
    width: 100%;
}