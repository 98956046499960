.title_period {
    margin-bottom: 0px;
    font-size: 14px;
    color: #025959;
    font-weight: 700;
    height: 20px;
}

.info__container{
    margin-top: 10px;
    margin-left: 10px;
}

.total__container{
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: #025959;
    font-weight: 700;
}

.total__column{
    flex: 0 0 33.33%;
    text-align: left;
}