.noti__container {
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 16px;
}

.base__container {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header__container{
    display: flex;
    flex-direction: row;
}

.circle__red {
    width: 15px;
    height: 15px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: red;
    margin-top: 4px;
    margin-right: 10px;
  }

.circle__grey {
    width: 15px;
    height: 15px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: rgb(141, 141, 141);
    margin-top: 4px;
    margin-right: 10px;
  }

.divider__style {
    background-color: #879393;
    width: 100%;
    border: none;
    margin-top: -4px;
}

.card__icon {
    margin-left: auto;
    font-size: 10px;
}