.input__form__session{
    width: 100%;
    box-shadow: #f0f0f0 0px 2px 20px 0px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    height: 50px;
    background-color: white;
    padding: 12px;
    -webkit-appearance: none;
    margin-bottom: 24px;
}


.input__form__session__error{
    width: 100%;
    box-shadow: #f0f0f0 0px 2px 20px 0px;
    border-radius: 5px;
    border: 1px solid #ff0000;
    height: 50px;
    background-color: white;
    padding: 12px;
    -webkit-appearance: none;
    font-weight: bold;
    color:#ff0000;
    margin-bottom: 24px;
}