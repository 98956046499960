.radio__container{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

.radio__container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
.radio__container input:checked ~ .checkmark {
    background-color: #037F8C;
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.radio__container input:checked ~ .checkmark:after {
    display: block;
}
  
.radio__container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
}