.login_container{
    width:100%;
    display: flex;
    justify-content: center;
}

.login_form{
    align-self: center;
    width: 600px;
    margin-left: 30px;
    margin-right: 30px;
}

.login_button {
    margin-top: 24px;
	box-shadow: 0px 7px 10px -7px #276873;
	background-color:#025959;
	border-radius:8px;
	display:inline-block;
    border: 0px;
	cursor:pointer;
	color:#ffffff;
	font-size:14px;
	font-weight:bold;
	padding:17px 48px;
	text-decoration:none;
    width: 100%;
}
.login_button:active {
	background-color:#004444;
}

.login_logo{
    margin-top: 90px;
    margin-bottom: 30px;
    text-align: center;
    color: teal;
    font-weight:bold;
    font-size: 32px;
}

.logo_img{ 
    padding-top: 50px;
    width: 200px; 
    height: 250px; 
    display: block; 
    margin: auto;
}

.login_instructions{
    margin-top: 10px;
    text-align: left;
    color: rgb(122, 122, 122);
    font-weight:bold;
    font-size: 16px;
}

.input_form{
    margin-top: 24px;
    width: 100%;
    box-shadow: #f0f0f0 0px 2px 20px 0px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    height: 50px;
    background-color: white;
    padding: 12px;
    -webkit-appearance: none;
}

