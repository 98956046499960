.form__container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form_input_container{
    width: 85%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.description__container{
  width: 100%;
}

.form_header_text {
  margin-bottom: 0px;
  font-size: 16px;
  color: #025959;
  font-weight: 700;
}


.form__title__remarks{
  margin-bottom: 0px;
  font-size: 20px;
  color: #025959;
  font-weight: 700;
}

.button__container{
  width: 100%;
}

@media screen and (min-width: 600px){
    .form_input_container {
      width: 60%;
    }

    .button__container{
      align-content: center;
      align-self: center;
      width: 60%;
    }
  }