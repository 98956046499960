.preview_modal_container {
    max-height: 400px;
    overflow-y: scroll;
}

.header__preview {
    margin-bottom: 0px;
    font-size: 16px;
    color: #025959;
    font-weight: 700;
}

.header__description {
    margin-bottom: 8px;
    font-size: 14px;
    color: #494949;
}

.header__preview__remarks {
    margin-bottom: 16px;
    font-size: 16px;
    color: #003d3d;
    font-weight: 800;

}