.updatepassword_container {
    align-self: center;
    width: 800px;
    margin-left: 30px;
    margin-right: 30px;
}

.password_form_instructions{
    margin-top: 40px;
    text-align: left;
    color: rgb(122, 122, 122);
    font-weight:bold;
    font-size: 16px;
}