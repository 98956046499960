.session__container{
  width: 100%;
  display: flex;
  justify-content: center;
}

.session__list {
    list-style: none;
    margin-top: 20px;
    padding: 0;
    width: 85%;
    align-content: center;
    align-self: center;
}

.placeholder {
    margin: 4rem;
    text-align: center;
}

.empty__icon {
  width: 130px; 
  height: 130px; 
  display: block; 
  margin: auto;
  margin-bottom: 6px;
}

.empty__container {
  margin-top: 100px;
  text-align: center;
  color: rgb(88, 88, 88);
}

@media screen and (min-width: 600px){
  .session__list {
    width: 60%;
  }
}