.edit__record__wrapper {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.edit__record__container {
    margin-right: 20px;
    margin-left: 20px;
    width: 85%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.form_header_text {
    margin-bottom: 0px;
    font-size: 16px;
    color: #025959;
    font-weight: 700;
}

.form_header_text_title {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #025959;
    font-weight: 700;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

.title__text {
    margin-bottom: 0px;
    color: #464646;
    font-weight: 500;
}

.description__record__text {
    margin-bottom: 8px;
    color: #464646;
    font-weight: 500;

}


.primary__button {
    margin-top: 4px;
    margin-bottom: 10px;
	box-shadow: 0px 7px 10px -7px #276873;
	background-color:#025959;
	border-radius:8px;
	display:inline-block;
    border: 0px;
	cursor:pointer;
	color:#ffffff;
	font-size:14px;
	font-weight:bold;
	padding:17px 48px;
	text-decoration:none;
    width: 100%;
}
.primary__button:active {
	background-color:#004444;
}

.primary__button__disabled {
    margin-top: 24px;
	box-shadow: 0px 7px 10px -7px #b3b5b6;
	background-color:#cccccc;
	border-radius:8px;
	display:inline-block;
    border: 0px;
	cursor:pointer;
	color:#444444;
	font-size:14px;
	font-weight:bold;
	padding:17px 48px;
	text-decoration:none;
    width: 100%;
}

.travel__time__container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.radio__container__edit {
    border: 1px;
    width: 20px;
    height: 20px;
    margin-top: 4px;
    margin-left: 8px;
}