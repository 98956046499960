.input__container{
    margin-top: 24px;
    width: 100%;
    box-shadow: #f0f0f0 0px 2px 20px 0px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    height: 50px;
    background-color: white;
    -webkit-appearance: none;
    display: flex;
    flex-direction: row;
}

.input__password {
    flex: 6;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ffffff;
    padding: 10px;
}

.input__icon {
    flex: 1;
    margin-top: 10px;
}