.float__button{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:30px;
	background-color:#025959;
	border-radius:50px;
	text-align:center;
    align-content: center;
	box-shadow: 2px 2px 3px #999;
}

.float__button:active {
    background: #004850;
}

.float__button__icon{
    color: white;
}