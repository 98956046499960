.menu{
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: -100%;
    z-index: 2;
    height: 100vh;
    width: 100%;
    transition: all 0.3s ease;
}

.menu__options{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-self: left;
    background: #025959;
    width: 75%;
    height: 100%;
}

.menu__empty__space{
    width: 25%;
    height: 100%;
}

.user__icon{
    align-self: center;
    color: white;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 75px;
 }

 .user__name{
    color: white;
    font-size: 14px;
    align-self: center;
 }

 .menu__option{
    color: white;
    margin-top: 10px;
    margin-left: 35px;
    font-size: 25px;
    font-style: bold;
    cursor: pointer;
 }
