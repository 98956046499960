.nav__root{
    background: #025959;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}

.nav__root__fixed{
    position: fixed;
    top: 0%;
}

.toolbar__section1{
    flex: 1;
    display: flex;
    align-content: flex-start;
    justify-content: start;
}

.toolbar__section2{
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
}

.toolbar__section3{
    flex: 1;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
}

.backButtonNavigation {
    margin-left: 30px;
    cursor: pointer;
    display: block;
    align-content: center;
    align-self: center;
    color: white;
}

.burgerButton {
    margin-left: 30px;
    cursor: pointer;
    display: block;
    align-content: center;
    align-self: center;
}

.burgerButton__bar{
    display: block;
    width: 20px;
    height: 2px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
    cursor: pointer;
}

.nav__logo{
    color: white;
    font-size: 18px;
    align-self: center;
}

.menu__background__transparent{
    position: fixed;
    display: none;
    z-index: 1;
    background-color: #000000;
    opacity: 0.5;
    height: 100vh;
    width: 100%;
 }

 .newSessionIcon__container{
    align-self: center;
    margin-right: 22px;
 }

 .newSessionIcon{
    font-size: 28px;
    align-self: center;
    color: white;
    margin-left: 12px;
 }

 .notification__icon {
    width: 30px; 
    height: 30px; 
  }

#click{
    display: none;
} 

#click:checked ~ .toolbar__section1 .burgerButton .burgerButton__bar:nth-child(2) {
    opacity: 0;
}

#click:checked ~ .toolbar__section1 .burgerButton .burgerButton__bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

#click:checked ~ .toolbar__section1 .burgerButton .burgerButton__bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

#click:checked ~ .menu {
    left: 0;
}

#click:checked ~ .menu__background__transparent {
    display: block;
}


@media screen and (min-width: 600px){
    .burgerButton {
        margin-left: 200px;
    }
}