.loader__background__transparent{
    position: fixed;
    display: block;
    z-index: 1;
    background-color: #000000;
    opacity: 0.4;
    height: 100%;
    width: 100%;
 }

 .loader__spinner{
    margin: 0;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
 }