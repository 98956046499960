.covidForm__container{
    width:100%;
    display: flex;
    justify-content: center;
}

.covidForm__form {
    align-self: center;
    width: 65%;
    box-shadow: #f0f0f0 0px 2px 20px 0px;
    border-radius: 5px;
    background-color: white;
    -webkit-appearance: none;
    margin-top: 50px;
}

.screen__title {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 25px;
    margin-left: 35px;
    text-align: center;
    color: #004444;
    font-weight:bold;
    font-size: 32px;
}

.covidForm__question{
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
    text-align: left;
    color: #004444;
    font-weight:bold;
    font-size: 18px;
}

.covidForm__radio__container {
    margin-left: 50px;
    margin-top: 15px;
    margin-bottom: 30px;
    display: block;
}

.covidForm__close__icon__container{
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    margin-right: 22px;
    margin-top: 22px;
}

.covidForm__close__icon{
    font-size: 35px;
    color: #004444;
}

@media (max-width: 700px) {
    .covidForm__form{
        margin-top: 0px;
        width:100%;
        box-shadow: none;
    }

    .covidForm__close__icon{
        font-size: 28px;
        color: #004444;
    }

}