.items__container{
    display: flex;
    margin-top: 2px;
    margin-bottom: 10px;
    flex-direction: row;
}

.from__container{
    margin-right: 10px;
}

.to_container{
    margin-right: 30px;
}

.date__picker{
    width: 150px;
}

