.toast__container__success {
    background-color: white;
}

.toast__title{
    margin-top: 12px;
    color: #07bc0c;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
}

.toast__description{
    color: #07bc0c;
    font-size: 14px;
}