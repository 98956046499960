.text__editor__container{
    z-index: 0;
    width: 100%;
}

.toolbarContainer {
    width: auto;
    margin-left: -21px;
    margin-right: -21px;
}

.formEditorContainer{
    margin-top: -18px;
    height: 375px;
}

.formEditor{
    height: 300px;
    margin-left: -21px;
    margin-right: -21px;
}

.icons__container{
    display: flex;
    margin-top: 2px;
    margin-bottom: 10px;
    flex-direction: row;
    margin-left: -12px;
    margin-right: -21px;
}

.custom__container__toolbar{
  margin-left: -20px;
  margin-right: -21px;
}

.iconsToolBar{
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 12px;
}

.ql-editor {
    font-size: 16px;
}

.toolbar_custom_icon{
  margin-top: 15px;
  margin-right: 12px;
}

.remark_disabled_container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  border: 1px solid rgb(233, 233, 233);
}

.remark__disabled__text {
  flex: 6;
  display: flex;
  margin-top: 10px;
  margin-left: 20px;
  color: rgb(128, 128, 128);
}

.remark__disabled__icon {
  margin-top: 10px;
  flex: 1;
  display: flex;
  color: #739b91;
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}
