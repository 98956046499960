.banner-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*
    background-color: #f8f8f8;
    */
    background-color: #e2f159;
    margin-bottom: 16px;
  }
  
  .banner-alert__message {
    margin-top: 12px;
    /*
    font-size: 13px;
    */
    font-size: 16px;
  }
  
  .banner-alert__divider {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 8px;
  }
  