.cue__container {
    margin-right: 14px;
    margin-bottom: 12px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
}

.cue__select__container{
    margin-left: 0px;
}

.cue__container > span{
    margin-top: 1px;
    margin-right: 4px;
}

.text__cue{
    font-size: 14px;
}